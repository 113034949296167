import React, { useCallback, useState, useEffect } from "react";
import fscreen from "fscreen";
import { useFullScreenNode } from "./full-screen-provider";

export interface FullScreenHandle {
  active: boolean;
  enter: () => Promise<void>;
  exit: () => Promise<void>;
  node: React.MutableRefObject<HTMLDivElement | null>;
}

export function useFullScreenHandle(): FullScreenHandle {
  const [active, setActive] = useState<boolean>(false);
  const node = useFullScreenNode();

  useEffect(() => {
    const handleChange = () => {
      setActive(fscreen.fullscreenElement === node.current);
    };
    fscreen.addEventListener("fullscreenchange", handleChange);
    return () => fscreen.removeEventListener("fullscreenchange", handleChange);
  }, []);

  const enter = useCallback(async () => {
    if (fscreen.fullscreenElement) {
      return fscreen.exitFullscreen();
    }
    if (node.current) {
      return fscreen.requestFullscreen(node.current);
    }
  }, []);

  const exit = useCallback(async () => {
    if (fscreen.fullscreenElement === node.current) {
      return fscreen.exitFullscreen();
    }
  }, []);

  return { active, enter, exit, node };
}
