import React, { useEffect, useRef, useState } from "react";
import PairingScreen from "./PairingScreen";
import { Button } from "./ui/button";
import { useAuth } from "@/hooks/useAuth";
import OrganizationScreen from "./OrganizationScreen";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
import { isAndroid, quitAndroidApp } from "@/utils/AndroidBridge";
import isInsideIframe from "../utils/isInsideIframe";
import { AlertDialogDescription } from "@radix-ui/react-alert-dialog";
import { gzLog } from "@/utils/gzAnalytics";

export default function DefaultSlideShowScreen() {
  const {
    storeIsPreviewMode,
    storeOrgId,
    storeDeviceId,
    isPreviewMode: ogIsPreviewMode,
  } = useAuth();
  const [isPreviewMode, setIsPreviewMode] = useState(ogIsPreviewMode);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const exitRef = useRef<HTMLButtonElement>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const yesRef = useRef<HTMLButtonElement>(null);
  const refs = [cancelRef, yesRef, exitRef];

  useEffect(() => {
    setTimeout(() => {
      if (isDialogOpen) {
        yesRef.current?.focus();
      }
    }, 100);
  }, [isDialogOpen]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowRight" || e.key === "ArrowDown") {
      e.preventDefault();
      const currentIndex = refs.findIndex(
        (ref) => ref.current === document.activeElement,
      );
      const nextIndex = (currentIndex + 1) % refs.length;
      refs[nextIndex]?.current?.focus();
    } else if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
      e.preventDefault();
      const currentIndex = refs.findIndex(
        (ref) => ref.current === document.activeElement,
      );
      const prevIndex = (currentIndex - 1 + refs.length) % refs.length;
      refs[prevIndex]?.current?.focus();
    }
  };

  function togglePreviewMode() {
    setIsDialogOpen(false);
    setIsPreviewMode((p) => !p);
    if (isPreviewMode) {
      storeIsPreviewMode(false);
      storeDeviceId();
      storeOrgId();
    }
  }

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      e.stopPropagation();

      gzLog({
        name: "KeyDown",
        eventtype: "KeyDown",
        payload: {
          code: e.code,
        },
      });

      switch (e.code) {
        case "Escape":
          setIsDialogOpen((o) => !o);
          break;
      }
    }

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <>
      {isPreviewMode ? <PreviewScreen /> : <PairingScreen />}
      <AlertDialog open={isDialogOpen}>
        <AlertDialogContent onKeyDown={handleKeyDown}>
          <AlertDialogHeader>
            <AlertDialogTitle className="text-3xl">
              Switch to {isPreviewMode ? "pairing" : "preview"} mode?
            </AlertDialogTitle>
            <AlertDialogDescription></AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="flex flex-row gap-4">
            {isAndroid() && (
              <Button
                ref={exitRef}
                onClick={quitAndroidApp}
                size="xl"
                variant="ghost"
                className="hover:bg-destructive/90 focus:scale-110 focus:bg-black focus:text-white focus-visible:ring-8 focus-visible:ring-green-400"
              >
                Exit App
              </Button>
            )}
            {isInsideIframe() && (
              <Button
                ref={exitRef}
                size="xl"
                variant="ghost"
                onClick={() => window.parent.postMessage("exit_app", "*")}
                className="hover:bg-destructive/90 focus:scale-110 focus:bg-black focus:text-white focus-visible:ring-8 focus-visible:ring-green-400"
              >
                Exit App
              </Button>
            )}
            <div className="flex-1"></div>
            <Button
              size="xl"
              onClick={() => setIsDialogOpen(false)}
              className="focus:scale-110 focus:bg-black focus:text-white focus-visible:ring-8 focus-visible:ring-green-400"
              variant="ghost"
              ref={cancelRef}
            >
              Cancel
            </Button>
            <Button
              ref={yesRef}
              size="xl"
              onClick={togglePreviewMode}
              variant="ghost"
              className="focus:scale-110 focus:bg-black focus:text-white focus-visible:ring-8 focus-visible:ring-green-600"
            >
              Continue
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

function PreviewScreen() {
  const { screenInfo } = useAuth();

  return (
    <div className="relative">
      {
        //<div className="absolute top-8 left-1/4 right-1/4 bg-foreground/80 px-4 py-2 rounded-md text-center text-background">Press Escape key to open menu</div>
      }
      {!screenInfo ? null : (
        <OrganizationScreen
          initialScreenInfo={screenInfo}
          key={screenInfo.id}
        />
      )}
    </div>
  );
}
