import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

const logsContext = createContext<LogsReturnProps | null>(null);
const { Provider } = logsContext;

type Props = {
  children: React.ReactNode;
  targetSequence: string[];
};

export function LogsProvider({ children, targetSequence }: Props) {
  const [pressedKeys, setPressedKeys] = useState<string[]>([]);
  const [logs, setLogs] = useState<string[]>([]);

  const [showLogs, setShowLogs] = useState(
    import.meta.env.VITE_ENVIRONMENT === "staging",
  );

  // Instead of using Next.js router, we can use URLSearchParams for query parameters
  const urlParams = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : {},
  );
  const hideLogs = urlParams.get("hideLogs");

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      setPressedKeys((prevKeys) => [...prevKeys, e.key]);

      if (pressedKeys.length > targetSequence.length) {
        setPressedKeys((prevKeys) => prevKeys.slice(1));
      }

      const _pressed = [...pressedKeys, e.key];

      if (JSON.stringify(_pressed) === JSON.stringify(targetSequence)) {
        setShowLogs((e) => !e);
        setPressedKeys([]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [pressedKeys, targetSequence]);

  return (
    <Provider
      value={{
        showLogs: showLogs && !(hideLogs === "true"),
        logs,
        setLogs,
      }}
    >
      {children}
    </Provider>
  );
}

export const useLogs: () => LogsReturnProps = () => {
  const context = useContext(logsContext);
  if (!context) throw "useLogs must be used within a LogsProvider";
  return context;
};

export interface LogsReturnProps {
  showLogs: boolean;
  logs: string[];
  setLogs: Dispatch<SetStateAction<string[]>>;
}
