import { WebsocketEvents, WebsocketEventTypes } from "./types";

class CustomWebSocket {
  public onOpen: ((this: WebSocket, ev: Event) => any) | null;
  public onMessage: ((this: WebSocket, ev: MessageEvent) => any) | null;
  public onError: ((this: WebSocket, ev: Event) => any) | null;
  public onClose: ((this: WebSocket, ev: CloseEvent) => any) | null;
  public onCallback: {
    [x: string]: (data?: any) => void;
  } = {};
  private readonly ws: WebSocket;

  constructor(url: string, _opts?: Record<string, any>) {
    this.ws = new WebSocket(url);

    this.onOpen = this.ws.onopen;
    this.onMessage = this.ws.onmessage;
    this.onError = this.ws.onerror;
    this.onClose = this.ws.onclose;

    this.ws.onopen = (ev) => {
      this.onOpen?.call(this.ws, ev);
    };
    this.ws.onmessage = this.onMessageCallback;
    this.ws.onerror = (ev) => {
      this.onError?.call(this.ws, ev);
    };
    this.ws.onclose = (ev) => {
      this.onClose?.call(this.ws, ev);
    };
  }

  public close() {
    this.ws.close();
  }

  onMessageCallback = (ev: MessageEvent) => {
    try {
      const data = JSON.parse(ev.data);
      const type = data?.type ?? "";
      const event = data?.event ?? "";

      if (type !== "callback") {
        this.onMessage?.call(this.ws, ev);
      } else {
        if (event) this.onCallback[event]?.(data.data);
      }
    } catch (_e) {
      this.onMessage?.call(this.ws, ev);
    }
  };

  public emit<T extends keyof typeof WebsocketEvents>(
    event: T,
    data: WebsocketEventTypes[T]["request"],
    cb?: (data?: WebsocketEventTypes[T]["response"]) => void,
  ) {
    this.ws.send(
      JSON.stringify({
        event: event,
        data: data,
      }),
    );

    if (cb) this.onCallback[event] = cb;
  }
}

export default CustomWebSocket;
