import isInsideIframe from "../../utils/isInsideIframe";
import { useFullScreenHandle } from "./use-full-screen-handle";

export function useFullScreen() {
  const handle = useFullScreenHandle();
  const shouldShowFullScreenButton =
    !navigator.userAgent.includes("TV") && !isInsideIframe();

  return { ...handle, shouldShowFullScreenButton };
}
