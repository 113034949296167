import * as Sentry from "@sentry/react";

export const initializeGzAnalytics = (onLoad?: () => void) => {
  const scriptUrl = import.meta.env.VITE_GZ_ANALYTICS_SCRIPT_URL;

  if (!scriptUrl) return;

  // @ts-ignore
  if (!window.GZA_INITIALIZED) {
    const analytics = document.createElement("script");
    analytics.src = scriptUrl;
    analytics.async = true;
    document.body?.appendChild(analytics);

    analytics.onload = () => {
      onLoad?.();
    };
  } else {
    onLoad?.();
  }
};

export const initializeAnalyticsWithDeviceId = (deviceId?: string | null) => {
  const systemId = import.meta.env.VITE_GZ_ANALYTICS_SYSTEM_ID;
  if (!systemId) return;

  initializeGzAnalytics(() => {
    // @ts-ignore
    window.GZAnalytics.init(systemId, deviceId);
  });
};

export interface gzLogProps {
  name:
    | "LoggedIn"
    | "TokenRefreshed"
    | "SendOrgIdAndDeviceIdToRoom"
    | "RequestDeviceIdFromRoom"
    | "SendDeviceIdToRoom"
    | "GenerateRoomId"
    | "ScreenChanged"
    | "LayoutChanged"
    | "PlaylistChanged"
    | "MusicChanged"
    | "ScreenAndLayoutInfo"
    | "PairSocketConnected"
    | "PairSocketDisconnected"
    | "SocketEvent"
    | "SocketConnected"
    | "SocketDisconnected"
    | "SocketError"
    | "KeyDown"
    | "ErrorBoundary";
  eventtype:
    | "Clicked"
    | "APICall"
    | "APISuccess"
    | "APIFailure"
    | "SocketEvent"
    | "KeyDown"
    | "SocketListenEvent"
    | "SocketEmitEvent"
    | "Error";
  payload: Record<string, any>;
}

function getLogLevel(
  eventtype: gzLogProps["eventtype"],
): Parameters<typeof Sentry.captureMessage>[1] {
  switch (eventtype) {
    case "Error":
    case "APIFailure":
      return "error";
    case "Clicked":
    case "APICall":
    case "APISuccess":
    case "SocketEvent":
    case "SocketListenEvent":
    case "SocketEmitEvent":
    case "KeyDown":
      return "log";
  }
}

export const gzLog = (props: gzLogProps) => {
  const logLevel = getLogLevel(props.eventtype);
  Sentry.captureMessage(JSON.stringify(props), logLevel);
  // @ts-ignore
  if (window.GZA_INITIALIZED) {
    //@ts-ignore
    window.GZAnalytics.customLog(props);
    if (import.meta.env.VITE_IS_DEV) {
      console.log(props);
    }
  }
};
