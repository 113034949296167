function PairingPinContainer({ randomPin }: { randomPin: string }) {
  return (
    <div
      aria-hidden="true"
      className="flex h-full w-full flex-col items-center justify-center gap-8 bg-primary"
    >
      <div className="flex flex-col gap-4">
        <p className="text-lg text-gray-300 md:text-[calc(6px+1.1vw)]">
          This screen is ready to be paired to your account
        </p>
        <div
          className={`flex flex-row items-center justify-center gap-2 font-ibmPlex`}
        >
          {randomPin.split("").map((char, index) => {
            return (
              <div
                aria-hidden="true"
                key={`pin_char_${index}`}
                className="flex h-[10vw] w-[10vw] flex-1 items-center justify-center rounded-md bg-card p-2 text-center text-[7vw] font-bold uppercase md:h-[7vw] md:w-[7vw] md:text-[5vw]"
              >
                {char}
              </div>
            );
          })}
        </div>
      </div>
      <p className="text-center text-lg text-gray-300 md:text-[calc(6px+1.3vw)]">
        Please enter this code in your account at{" "}
        <span className="text-yellow-300">
          <a
            href={"https://app.signagestudio.io/screens"}
            target="_blank"
            rel="noreferrer"
          >
            app.signagestudio.io
          </a>
        </span>
        .
      </p>
    </div>
  );
}

export default PairingPinContainer;
