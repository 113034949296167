import {
  PlaylistItem,
  RuleSetItemType,
  RuleSetType,
} from "@/types/playlistTypes";
import { DateTime } from "luxon";

export const luxonDateFormat = "yyyy-LL-dd, HH:mm";

export default function isPlaylistItemActive(item: PlaylistItem): boolean {
  const _now = DateTime.now();

  if (item.startDateTime) {
    const start = DateTime.fromFormat(item.startDateTime, luxonDateFormat);
    if (start > _now) return false;
  }

  if (item.pausedUntilDateTime) {
    const pausedUntil = DateTime.fromFormat(
      item.pausedUntilDateTime,
      luxonDateFormat,
    );
    if (pausedUntil > _now) return false;
  }

  if (item.rule) {
    return checkRuleSet(item.rule, _now);
  }
  return true;
}

function checkRuleSet(ruleSet: RuleSetType, now: DateTime): boolean {
  const res = ruleSet.rules.some(function (rule) {
    return rule.every(function (e) {
      return checkSingleRule(e, now);
    });
  });

  return ruleSet.type === "allowed" ? res : !res;
}

function checkSingleRule(rule: RuleSetItemType, now: DateTime): boolean {
  if (rule.values.length === 0) return true;
  const _formatted = now.toFormat(rule.format);
  return rule.values.includes(_formatted);
}
