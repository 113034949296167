import React, { createContext, useContext, useEffect, useState } from "react";
import CustomWebSocket from "../../utils/customWebSocket";
import { gzLog, gzLogProps } from "@/utils/gzAnalytics";
import { useAuth } from "../useAuth";
import { useLogs } from "../useLogs";

const usePairingContext = createContext<UsePairingReturnProps | null>(null);
const { Provider } = usePairingContext;
type Props = {
  children: React.ReactNode;
};

export function PairingProvider({ children }: Props) {
  let socket!: CustomWebSocket;

  const [randomPin, setRandomPin] = useState<string | null>(null);

  const { deviceId, loginViaDeviceId } = useAuth();

  const { setLogs } = useLogs();

  function log(props: gzLogProps) {
    setLogs((e) => [...e, JSON.stringify(props)]);
    gzLog(props);
  }

  useEffect(() => {
    const devId = deviceId;

    socket = new CustomWebSocket(
      import.meta.env.VITE_WS_URL! +
        "/" +
        import.meta.env.VITE_WS_PAIRING_NAMESPACE! +
        "/public/websocket",
    );

    socket.onOpen = () => {
      generateRandomPin();
      log({
        name: "PairSocketConnected",
        eventtype: "SocketEvent",
        payload: {
          deviceId: devId,
          randomPin,
        },
      });
    };

    socket.onClose = () => {
      log({
        name: "PairSocketDisconnected",
        eventtype: "SocketEvent",
        payload: {
          deviceId: devId,
          randomPin,
        },
      });
    };

    socket.onMessage = (message) => {
      try {
        const data = JSON.parse(message.data);
        const event = data.event;

        if (event) {
          switch (event) {
            case "sendOrgIdAndDeviceIdToRoom":
              const { orgId, deviceId } = data.data;
              log({
                name: "SendOrgIdAndDeviceIdToRoom",
                eventtype: "SocketListenEvent",
                payload: {
                  deviceId: deviceId,
                  orgId: orgId,
                },
              });
              loginViaDeviceId(deviceId, orgId);
              break;
            case "requestDeviceIdFromRoom":
              const roomId = data.data;
              const w = window.outerWidth;
              const h = window.outerHeight;
              log({
                name: "RequestDeviceIdFromRoom",
                eventtype: "SocketListenEvent",
                payload: {
                  roomId: roomId,
                },
              });
              socket.emit("sendDeviceIdToRoom", {
                deviceId: devId ?? undefined,
                roomId: roomId,
                width: w,
                height: h,
              });

              log({
                name: "SendDeviceIdToRoom",
                eventtype: "SocketEmitEvent",
                payload: {
                  deviceId: devId ?? undefined,
                  roomId: roomId,
                  width: w,
                  height: h,
                },
              });
              break;
            default:
              break;
          }
        }
      } catch {
        console.log("error");
      }
    };

    return () => {
      socket.close();
    };
  }, []);

  async function generateRandomPin() {
    if (randomPin) return;
    const pin = randomString(6);
    log({
      name: "GenerateRoomId",
      eventtype: "SocketEmitEvent",
      payload: {
        roomId: pin,
      },
    });
    socket.emit(
      "hasRoom",
      {
        roomId: pin,
      },
      async (val?: boolean) => {
        if (val) {
          generateRandomPin();
        } else {
          socket.emit(
            "joinRoom",
            {
              roomId: pin,
              id: pin,
              type: "pairing",
            },
            () => {
              setRandomPin(pin);
            },
          );
        }
      },
    );
  }

  function randomString(length: number) {
    return Math.round(
      Math.pow(36, length + 1) - Math.random() * Math.pow(36, length),
    )
      .toString(36)
      .slice(1);
  }

  return (
    <Provider
      value={{
        socket,
        randomPin,
        setRandomPin,
      }}
    >
      {children}
    </Provider>
  );
}

export const usePairing: () => UsePairingReturnProps = () => {
  const context = useContext(usePairingContext);
  if (!context) throw "useUsePairing must be used within a UsePairingProvider";
  return context;
};

interface UsePairingReturnProps {
  socket: CustomWebSocket;
  randomPin: string | null;
  setRandomPin: (randomPin: string | null) => void;
}
