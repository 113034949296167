import React, { useEffect, useState } from "react";
import { Asset, Playlist, Screen } from "@repo/db/types";
import LoadingContainer from "../LoadingConitainer";
import AssetPlayer from "./AssetPlayer";
import LayoutPlayer from "./LayoutPlayer";
import { PlaylistItem } from "@/types/playlistTypes";
import isPlaylistItemActive from "../../utils/isPlaylistItemActive";
import { groupBy } from "@/utils";
import { AnimatePresence, motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { getPlaylistInfo } from "@repo/api";

export type PlaylistPlayerProps = {
  playlist?: Playlist;
  screen: Screen;
}

const PlaylistPlayer = React.forwardRef<HTMLDivElement, PlaylistPlayerProps>(({
  playlist,
  screen,
}, ref) => {
  const [currentFrame, setCurrentFrame] = useState<PlaylistItem | null>(null);

  const { data: fetchedPlaylist, isSuccess } = useQuery({
    queryKey: ["playlistInfo", playlist?.id],
    // @ts-ignore
    queryFn: async () => {
      if (!playlist?.id) return;
      return await getPlaylistInfo({
        data: {
          playlistId: playlist.id,
        },
      });
    },
    enabled: !!playlist?.id,
  });

  useEffect(() => {
    if (isSuccess) {
      initializePlaylist(
        fetchedPlaylist?.items ?? ([] as PlaylistItem[]),
      ).catch(console.error);
    }
  }, [isSuccess]);

  async function initializePlaylist(playlistItems: PlaylistItem[]) {
    const _grouped = Object.values(groupBy(playlistItems, "playOrder"));

    if (playlistItems.length === 0) return;

    let _frameIdx = 0;
    // @ts-ignore
    window.frameIdx = _frameIdx;
    do {
      const _framePool = _grouped[_frameIdx];
      if (!_framePool) continue;
      const _randomIndex = Math.floor(Math.random() * _framePool?.length);
      const _newPlaylistItem = _framePool[_randomIndex];

      if (!_newPlaylistItem) continue;

      if (isPlaylistItemActive(_newPlaylistItem)) {
        setCurrentFrame(_newPlaylistItem);
        await new Promise((resolve) =>
          setTimeout(resolve, _newPlaylistItem.duration * 1000),
        );
      }

      if (_frameIdx === _grouped.length - 1) {
        _frameIdx = 0;
      } else {
        _frameIdx++;
      }
      // @ts-ignore
      window.frameIdx = _frameIdx;
      // eslint-disable-next-line no-constant-condition
    } while (true);
  }

  if (!fetchedPlaylist) return <LoadingContainer />;

  if (!currentFrame) return <>No Frames Active</>;

  function getTransitionVariant(_idx: number) {
    try {
      // @ts-ignore
      const transition = fetchedPlaylist?.transition;
      if (!transition) return;

      const _variants =
        typeof transition === "string" ? JSON.parse(transition) : transition;

      // @ts-expect-error - frameIdx might not be in window
      const _frameIdx = window.frameIdx;

      if (!_frameIdx) return undefined;

      const key = _frameIdx === 0 ? "0-1" : `${_frameIdx - 1}-${_frameIdx}`;

      // const key = "";
      if (key in _variants) {
        return _variants[key];
      }
      return undefined;
    } catch (_e) {
      return;
    }
  }

  return (
    <AnimatePresence initial={false}>
      <motion.div
        className="flex h-full w-full items-center justify-center overflow-clip"
        key={currentFrame.id}
        initial="initial"
        variants={getTransitionVariant(0)}
        animate="animate"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
      >
        {currentFrame.type === "asset" ? (
          <AssetPlayer
            screen={screen}
            asset={fetchedPlaylist.assetsInfo.find(
              (v: Asset) => v.id === currentFrame.id,
            )}
          />
        ) : (
          <LayoutPlayer
            ref={ref}
            key={`layout_${currentFrame.id}`}
            layoutInfo={fetchedPlaylist.layoutsInfo.find(
              (v) => v.id === currentFrame.id,
            )}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
})

export default PlaylistPlayer;