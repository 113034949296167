import DefaultSlideShowScreen from "@/components/DefaultSlideShowScreen";
import ExitPopupDialog from "@/components/ExitPopupDialog";
import LoadingContainer from "@/components/LoadingConitainer";
import OrganizationScreen from "@/components/OrganizationScreen";
import PairingScreen from "@/components/PairingScreen";
import LogsContainer from "@/components/ui/LogsContainer";
import { AuthProvider, useAuth } from "@/hooks/useAuth";
import { FullScreenProvider } from "@/hooks/useFullScreen/full-screen-provider";
import { LogsProvider } from "@/hooks/useLogs";
import appCss from "@/styles/globals.css?url";
import { gzLog } from "@/utils/gzAnalytics";
import isInsideIframe from "@/utils/isInsideIframe";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRootRoute, ScrollRestoration } from "@tanstack/react-router";
import { Meta, Scripts } from "@tanstack/start";
import { ReactNode, useEffect } from "react";

// Sentry.init({
//   dsn: "https://4d79fb897e80464191ad698d37f35c3a@o123862.ingest.us.sentry.io/4504444532621312",
//   //dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.browserProfilingIntegration(),
//     Sentry.replayIntegration(),
//   ],
// });

export const Route = createRootRoute({
  head: () => ({
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Signage Studio is a free and open source platform for creating and sharing digital signage.",
      },
      {
        name: "keywords",
        content:
          "Signage Studio, Signage, Digital Signage, Player, Player Signage, Share Signage Player",
      },
      {
        title: "Signage Studio Player",
      },
    ],
    links: [{ rel: "stylesheet", href: appCss }],
  }),
  ssr: false,
  component: Root,
  errorComponent: () => (
    <RootDocument>
      <ExitPopupDialog hasError />
    </RootDocument>
  ),
});

const queryClient = new QueryClient();

export function Root() {
  return (
    <RootDocument>
      <App />
    </RootDocument>
  );
}

export function App() {
  useEffect(() => {
    if (isInsideIframe()) {
      setTimeout(() => {
        window.parent.postMessage("app_loaded", "*");
      }, 100);
    }
  }, []);

  useEffect(() => {
    const {
      outerWidth,
      outerHeight,
      innerWidth,
      innerHeight,
      screen: {
        width,
        height,
        availWidth,
        availHeight,
        orientation: { type, angle },
      },
    } = window;
    gzLog({
      // @ts-ignore
      name: "DeviceSizeInfo",
      // @ts-ignore
      eventtype: "ScreenSizeEvent",
      payload: {
        outerWidth,
        outerHeight,
        innerWidth,
        innerHeight,
        screen: JSON.stringify({
          width,
          height,
          availWidth,
          availHeight,
          orientation: { type, angle },
        }),
      },
    });
  }, []);

  return (
    <FullScreenProvider>
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </FullScreenProvider>
  );
}

function Main() {
  return (
    <LogsProvider
      targetSequence={[
        "ArrowLeft",
        "ArrowRight",
        "ArrowRight",
        "ArrowLeft",
        "ArrowRight",
      ]}
    >
      <div
        aria-hidden="true"
        className="relative"
        tabIndex={-1}
        id="rootElement"
      >
        <div aria-hidden="true" className="absolute">
          <AuthProvider>
            <Home />
          </AuthProvider>
        </div>
      </div>
    </LogsProvider>
  );
}

function Component() {
  const { screenInfo, isPreviewMode, screenInfoFetchStatus, hideLoading } =
    useAuth();

  if (
    !hideLoading &&
    (screenInfoFetchStatus === "pending" ||
      screenInfoFetchStatus === "fetching")
  )
    return <LoadingContainer />;

  if (isPreviewMode) return <DefaultSlideShowScreen />;
  if (!screenInfo) return (
    <ExitPopupDialog>
      <PairingScreen />
    </ExitPopupDialog>
  );

  return (
    <ExitPopupDialog>
      <OrganizationScreen initialScreenInfo={screenInfo} key={screenInfo.id} />
    </ExitPopupDialog>
  );
}

function Home() {
  return (
    <div aria-hidden="true" className="h-screen w-screen">
      <Component />

      <LogsContainer />
    </div>
  );
}

function RootDocument({ children }: Readonly<{ children: ReactNode }>) {
  return (
    <html className="font-poppins">
      <head>
        <Meta />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
